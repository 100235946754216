import { IExportarReembolsoAnaliticoProps } from "../../types/exportar";
import { removeEmpty } from "../../utils/removeEmpty";

const basePath = "/exportar";

export class Exportar {
  static async Reembolso_analitico(props: IExportarReembolsoAnaliticoProps) {
    const values = removeEmpty(props);
    const params = new URLSearchParams(values).toString();

    const token = sessionStorage.getItem("@token");
    let path = `${process.env.REACT_APP_BRAVE_API_URL}${basePath}/reembolso-analitico?${params}`;

    const response = await fetch(path, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token.replaceAll('"', ""),
      },
    });

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "relatorio.xlsx";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
